@import 'src/assets/scss/mixins';

.summary {
  margin-top: 75px;
  margin-bottom: 75px;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__campaign-level {
    display: flex;
    column-gap: 2rem;
    margin-left: 1.2rem;
  }

  &__adSets-level {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .adSet {
      margin-left: 25px;
      display: flex;
      column-gap: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__ads-level {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .ad {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      margin-top: 1rem;
      .media {
        display: flex;
        gap: 1rem;
      }
    }
  }

  &__brand-info {
    margin-top: 40px;
  }

  &__section {
    padding-left: 16px;
    border-left: 1px solid $color-gray;
    min-width: 236px;
    max-width: 450px;
    @include size('phone') {
      margin-left: 0;
    }

    &-title,
    .title {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      gap: 3px;
    }

    &-content {
      &-block {
        margin: 10px 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
        max-width: 450px;
        .option {
          margin-left: 15px;
        }

        .conversion-event-name {
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &-flex {
        margin: 10px 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;
        gap: 3px;
        max-width: 450px;
        flex-wrap: wrap;
        .option {
          margin-left: 15px;
        }

        span {
          line-break: anywhere;
        }

        .conversion-event-name {
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .pixel-doc-link {
        color: $color-violet;
        text-decoration: none;
      }

      .cta-image {
        max-width: 600px;
      }
    }
  }

  .buttons {
    width: 100%;
    margin: 50px 0 50px 0;
    display: flex;
    button {
      width: 233px;
    }
  }

  .notice {
    font-size: 0.8rem;
    color: $color-violet;
    font-style: italic;
  }

  .warning {
    @extend .notice;
    color: $color-error;
  }

  .accented {
    font-weight: 600;
  }

  .image-section {
    display: flex;
    align-items: center;

    &__picture {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border: 1px solid $color-black;
      border-radius: 20px;
      overflow: hidden;

      .img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }

    &__name {
      font-size: 0.8em;
      line-height: 100%;
      font-weight: 400;
      margin-right: 30px;
    }
  }

  .media-wrap {
    max-width: 600px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .video-container {
        width: 200px;
        background: $color-lightGray;
        aspect-ratio: 9/16;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .image {
        max-height: 150px;
        height: 100%;
        display: block;
        margin-right: auto;
        object-fit: contain;
      }
    }
  }

  .popup-hyperlink-container {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    .popup-hyperlink {
      width: auto;
      color: $color-violet;
      font-size: 12px;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;

      &.disabled {
        font-size: 12px;
        pointer-events: none;
        color: lightgray;
      }
    }

    .qrCode-container {
      max-width: 80px;
    }
  }
}
