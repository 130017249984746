@import 'src/assets/scss/mixins';

.overview {
  &-header {
    font-size: 1.333em;
    line-height: 100%;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 50px;
  }

  &-content {
    width: 100%;
    max-width: 960px;
    margin-top: -1rem;

    &__grid--small {
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px 20px;
      grid-template-areas: '. . .';

      @include size('tablet') {
        grid-template-areas: '. .';
      }

      @include size('phone-big') {
        display: block;
        width: 100%;
      }
    }

    &__grid--medium {
      margin-top: 10px;
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 20px 20px;
      grid-template-areas: '. .';

      @include size('phone-big') {
        display: block;
        width: 100%;
      }
    }

    .grid-block {
      @include size('phone-big') {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    &__flex--large {
      margin-top: 10px;
      padding: 5px;
    }

    &__flex--medium {
      padding: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .flex-item,
      .question-item {
        width: 49%;

        @include size('tablet-graph') {
          width: 100%;

          &:not(:first-child) {
            margin-top: 20px;
          }
        }

        .no-data {
          min-height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3333em;
        }
      }

      .question-item {
        margin-top: 20px;
      }
    }

    &__media {
      width: 100%;
      max-width: 650px;
      height: auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 60px;

      .media-item {
        &__video {
          width: 300px;
          height: auto;
          margin-right: 20px;
        }

        &__cta {
          width: 300px;

          .img__wrap {
            width: 100%;

            .img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }

      .title {
        margin-bottom: 10px;
      }
    }

    &__details {
      .logo-container {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        &__picture {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border: 1px solid $color-black;
          border-radius: 20px;
          overflow: hidden;

          .img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }
        }

        &__name {
          font-size: 0.8em;
          line-height: 100%;
          font-weight: 400;
          margin-right: 30px;
        }
      }

      .detail-container {
        max-width: 800px;
        font-size: 0.85em;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-bottom: 50px;

        .accented {
          font-weight: 600;
        }
      }
    }

    &__grid-overall,
    &__grid-targeting {
      max-width: 800px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px 20px;

      @include size('tablet') {
        display: block;
      }
    }

    &__grid-overall {
      grid-template-areas:
        '. .'
        '. .';
    }

    &__grid-targeting {
      margin-top: 50px;
      grid-template-areas:
        '. .'
        '. .'
        '. .'
        '. .';
    }

    .grid-item {
      @include size('tablet') {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    // &:last-child {
    //   margin-bottom: 100px;
    // }
  }
}
